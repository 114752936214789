
import axios from '@/utils/axios'

// 优惠明细统计
export const discountDetails = data =>
  axios.post('/crm/OrderStatistics/DiscountDetails', data)
    .then(res => res)

// 财务报表（销售日报表）
export const dayDetails = data =>
  axios.post('/crm/OrderStatistics/DayDetails', data)
    .then(res => res)

// 员工业绩统计
export const staffScore = params => axios({
    method: 'get',
    url: '/admin/Statistics/readAdminShopPersonStatistics',
    params
}).then(res => res.data)

// 员工业绩统计修改提成
export const updatePersonCommission = data =>
  axios.post('/admin/Statistics/updatePersonCommission', data)
    .then(res => res)

// 任务队列
export const addTaskQueue = data =>
  axios.post('/admin/Statistics/addTaskQueue', data)
    .then(res => res)

// 任务状态
export const getTaskQueueStatus = params => axios({
    method: 'get',
    url: '/admin/Statistics/getTaskQueueStatus',
    params
}).then(res => res)

// 进店成交明细表
export const OrderDetails = data =>
  axios.post('/crm/OrderStatistics/OrderDetails', data)
    .then(res => res)

// 进店其他交易明细
export const bargainQita = data =>
  axios.post('/crm/OrderStatistics/bargainQita', data)
    .then(res => res)

// 进店保有率明细
export const EnterDetails = data =>
  axios.post('/crm/OrderStatistics/EnterDetails', data)
    .then(res => res)

// 月度消耗明细
export const readAdminCustomer = data =>
axios.post('/admin/Statistics/readAdminCustomerByShopId', data)
  .then(res => res)

// 月度业绩明细
export const readAdminShopAmountList = data =>
axios.post('/admin/Statistics/readAdminShopAmountList', data)
  .then(res => res)

// 月度新补单明细
export const readAdminNewTypeList = data =>
axios.post('/admin/Statistics/readAdminNewTypeList', data)
  .then(res => res)


// 成交保有率明细
export const DealDetails = data =>
  axios.post('/crm/OrderStatistics/DealDetails', data)
    .then(res => res)

  // 门店线上导客分析
  export const shopDk1 = data =>
    axios.post('/crm/customerTj/shopDk1', data)
      .then(res => res)

  //门诊护理统计
  export const getOutpatientService = data =>
    axios.post('/crm/customerTj/getOutpatientService', data)
      .then(res => res)

      //门诊护理统计
  export const OutpatientCareDetail = data =>
    axios.post('/crm/customerTj/OutpatientCareDetail', data)
      .then(res => res)


// 门店线上导客分析
export const shopDk = data =>
  axios.post('/crm/customerTj/shopDk', data)
    .then(res => res)

// 转介绍明细表
export const transferIntroduction = data =>
  axios.post('/crm/customerTj/TransferIntroduction', data)
    .then(res => res)

// 资金统计
export const readAdminCapitalStatistics = data =>
  axios.post('/admin/Statistics/readAdminCapitalStatistics', data)
    .then(res => res)

// 业绩达成统计
export const readAdminShopAchievementStatistics = data =>
  axios.post('/admin/Statistics/readAdminShopAchievementStatistics', data)
    .then(res => res)

// 店面其他信息
export const readAdminShopStatistics = data =>
  axios.post('/admin/Statistics/readAdminShopStatistics', data)
    .then(res => res)

// 门店线上导客分析的内页
export const shopDkDetail = data =>
  axios.post('/crm/customerTj/shopDkDetail', data)
    .then(res => res)

// 客服月度任务录入
export const skTask = data =>
  axios.post('/crm/customerTj/moneth_kf_task', data)
    .then(res => res)

// 月度客服进店报表
export const monethCustomer = data =>
  axios.post('/crm/customerTj/moneth_customer_report_form', data)
    .then(res => res)

// 员工业绩统计
export const clientServe = params => axios({
  method: 'get',
  url: '/admin/AdminUser/readCustomerAdminUserList',
  params
}).then(res => res.data)

// 新增员工业绩月度任务
export const shopTaskS = data =>
  axios.post('/admin/Statistics/shopTask', data)
    .then(res => res)

// 门店的月度任务
export const moneth_mk_task = data =>
  axios.post('/crm/customerTj/moneth_mk_task', data)
    .then(res => res)

// 新增门店的月度任务
export const shopTask = data =>
  axios.post('/crm/customerTj/moneth_shop_task', data)
    .then(res => res)

// 月度门店报表
export const monethShop = params => axios({
  method: 'get',
  url: '/crm/customerTj/moneth_shop_report_form',
  params
}).then(res => res.data)

// 月度市场报表
export const monethMarket = params => axios({
  method: 'get',
  url: '/crm/customerTj/moneth_market_report_form',
  params
}).then(res => res.data)

// 进店成交明细二级页面
export const OrderStatistics = params => axios({
  method: 'get',
  url: '/crm/OrderStatistics/Details',
  params
}).then(res => res.data)

// 店面余额报表
export const getZyCustomer = data =>
  axios.post('/crm/customerTj/getZyCustomer3', data)
    .then(res => res)


// 会员数据统计
export const huiyuan_tongji = params => axios({
  method: 'get',
  url: '/crm/statisticHuiyuanTj/huiyuan_tongji',
  params
}).then(res => res.data)

// 预备会员列表
export const getYBVipList = params => axios({
  method: 'get',
  url: '/crm/statisticHuiyuanTj/getYBVipList',
  params
}).then(res => res.data)

// 品项分析数据列表
export const productAnalysis = params => axios({
  method: 'get',
  url: '/crm/productAnalysisTj/setProductAnalysis',
  params
}).then(res => res.data)

// 品项分析数据明细
export const getShopProductList = params => axios({
  method: 'get',
  url: '/crm/productAnalysisTj/getShopProductList',
  params
}).then(res => res.data)
// 商品列表
export const getGoodsList = params => axios({
  method: 'get',
  url: '/crm/productAnalysisTj/getGoodsList',
  params
}).then(res => res.data)

/* 黄V */
export const getYellowV = data =>
  axios.post('/crm/customer/getYellowV', data)
    .then(res => res)

/* 黄V客服 */
export const getYellowVService = data =>
  axios.post('/crm/customer/getYellowVService', data)
    .then(res => res)

/* 黄V护理师 */
export const getYellowVPhysician = data =>
    axios.post('/crm/customer/getYellowVPhysician', data)
      .then(res => res)
/* 客户分析表 */
export const getAnalysis = data =>
    axios.post('/shop/refund/getAnalysis', data)
      .then(res => res)
/*导入任务 */
export const importShopAchievementExcel = data =>
    axios.post('/admin/Statistics/importShopAchievementExcel', data)
      .then(res => res)
/*客户标签 */
export const getTagCount = params => axios({
  method: 'get',
  url: '/crm/customer/getTagCount',
  params
}).then(res => res)
// 保有数据报表
export const getRetainList = data =>
  axios.post('/crm/ShopRetention/getRetentionList', data)
    .then(res => res)
// 保有用户详细列表
export const getCustomerDetails = data =>
  axios.post('/crm/ShopRetention/getCustomerDetails', data)
    .then(res => res)

export const getMonthCustomerInfo = params =>
  axios.get('/crm/customerTj/getMonthCustomerInfo', {params: params})
    .then(res => res)