var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('el-row',[_c('el-col',{attrs:{"span":20}},[_c('div',{staticClass:"accountCon-title"},[_c('span',[_vm._v(_vm._s(_vm.level)+"客"),_c('span',[_vm._v(_vm._s(_vm.type == 'all' ? '': _vm.type == 'baoyou' ? '保有': _vm.type == 'liushi' ? '流失' : _vm.type == 'huiliu' ? '回流数':'流失预警数'))]),_vm._v("数量详情：")])])]),_c('el-col',{attrs:{"span":4}},[(_vm.admin_level == 3)?_c('el-button',{staticClass:"mb10",staticStyle:{"float":"right"},attrs:{"type":"primary","size":"mini","loading":_vm.exportS},on:{"click":_vm.exportExcel}},[_vm._v("导出数据")]):_vm._e()],1)],1),_c('el-row',{staticStyle:{"margin-bottom":"20px"}},[_c('el-alert',{attrs:{"type":"info","title":"ABCD客数量:门店成交的所有新单客户（客户首单金额大于等于500）滚动一年范围内在门店和门诊累计消费金额大于等于50000\\15000小于50000\\5000小于15000\\500小于5000。","closable":false}}),_c('el-alert',{attrs:{"type":"info","title":"ABCD客保有:A\\B\\C\\D客数量在最近三个月内门店/门诊有消耗或消费任何一个即为保有。","closable":false}})],1),_c('el-row',[_c('el-col',{attrs:{"span":24}},[_c('el-table',{attrs:{"border":"","size":"small","data":_vm.list,"cell-style":_vm.$style.cellStyle,"header-cell-style":_vm.$style.rowClass}},[_c('ElTableColumn',{attrs:{"label":"客户姓名"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('div',{staticClass:"hand",on:{"click":function($event){return _vm.goCustomerDetail(row)}}},[_vm._v(_vm._s(row.customer_name))])]}}])}),_c('ElTableColumn',{attrs:{"label":"客户手机号","prop":"telephone"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
var $index = ref.$index;
return [_c('el-popover',{attrs:{"placement":"top-start","transition":'',"popper-options":_vm.popperOptions},model:{value:(row.popVisible),callback:function ($$v) {_vm.$set(row, "popVisible", $$v)},expression:"row.popVisible"}},[_c('img',{staticClass:"img",attrs:{"src":row.phoneImageUrl}}),_c('span',{staticStyle:{"display":"block","cursor":"pointer"},attrs:{"slot":"reference"},on:{"mouseleave":function($event){return _vm.closePhoneImage($index)},"mouseenter":function($event){return _vm.openPhoneImage($index,row.id)}},slot:"reference"},[_vm._v(_vm._s(row.telephone))])])]}}])}),_c('ElTableColumn',{attrs:{"label":"性别"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('span',[_vm._v(_vm._s(row.sex == 1 ? '男': row.sex == 2 ? '女':'未知'))])]}}])}),_c('ElTableColumn',{attrs:{"label":"所属门店","prop":"shop_name"}}),_c('ElTableColumn',{attrs:{"label":"来源","prop":"source_name"}}),_c('ElTableColumn',{attrs:{"label":"客户预存","prop":"yucun"}}),_c('ElTableColumn',{attrs:{"label":"保有/流失","prop":"baoliu"}}),_c('ElTableColumn',{attrs:{"label":"年度消费金额","prop":"year_amount"}}),_c('ElTableColumn',{attrs:{"label":"门诊消费金额","prop":"mz_amount"}}),_c('ElTableColumn',{attrs:{"label":"门店消费金额","prop":"md_amount"}}),_c('ElTableColumn',{attrs:{"label":"门店贡献价值","prop":"gongxian"}}),_c('ElTableColumn',{attrs:{"label":"最新消费时间","prop":"last_xiaofei"}}),_c('ElTableColumn',{attrs:{"label":"最新核销时间","prop":"last_hexiao"}}),_c('ElTableColumn',{attrs:{"label":"成交时间","prop":"cj_time"}}),_c('ElTableColumn',{attrs:{"label":"护理师","prop":"nurse_name"}}),_c('ElTableColumn',{attrs:{"label":"最新回访时间","prop":"last_kftime"}}),_c('ElTableColumn',{attrs:{"label":"流失预警日期","prop":"warning_time"}})],1),_c('pagination',{staticClass:"page tc mt10",attrs:{"total":_vm.page.total,"page":_vm.page.page,"limit":_vm.page.limit},on:{"update:page":function($event){return _vm.$set(_vm.page, "page", $event)},"update:limit":function($event){return _vm.$set(_vm.page, "limit", $event)},"pagination":_vm.getList}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }