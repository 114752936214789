<template>
  <div>
    <el-row>
      <el-col :span="20">
        <div class="accountCon-title">
          <span>{{level}}客<span>{{type == 'all' ? '': type == 'baoyou' ? '保有': type == 'liushi' ? '流失' : type == 'huiliu' ? '回流数':'流失预警数'}}</span>数量详情：</span>
        </div>
      </el-col>
      <el-col :span="4">
        <el-button type="primary" class="mb10" size="mini" style="float: right;"  :loading="exportS" @click="exportExcel" v-if="admin_level == 3">导出数据</el-button>
      </el-col>
    </el-row>
    <el-row style="margin-bottom: 20px;">
      <el-alert
        type="info"
        title="ABCD客数量:门店成交的所有新单客户（客户首单金额大于等于500）滚动一年范围内在门店和门诊累计消费金额大于等于50000\15000小于50000\5000小于15000\500小于5000。"
        :closable="false">
      </el-alert>
      <el-alert
        type="info"
        title="ABCD客保有:A\B\C\D客数量在最近三个月内门店/门诊有消耗或消费任何一个即为保有。"
        :closable="false">
      </el-alert>
    </el-row>
    <el-row>
      <el-col :span="24">
        <el-table
          border
          size="small"
          :data="list"
          :cell-style="$style.cellStyle"
          :header-cell-style="$style.rowClass"
        >
          <ElTableColumn label="客户姓名">
            <template slot-scope="{row}" >
              <div class="hand"  @click="goCustomerDetail(row)">{{ row.customer_name }}</div>
            </template>
          </ElTableColumn>
          <ElTableColumn label="客户手机号" prop="telephone">
            <template slot-scope="{row,$index}">
              <el-popover 
                placement="top-start"
                :transition="''"
                :popper-options="popperOptions"
                v-model="row.popVisible">
                <img :src="row.phoneImageUrl" class="img">
                <span slot="reference" @mouseleave="closePhoneImage($index)" @mouseenter="openPhoneImage($index,row.id)" style="display:block;cursor: pointer;">{{row.telephone}}</span> 
              </el-popover>
            </template>
          </ElTableColumn>
          <ElTableColumn label="性别" >
            <template slot-scope="{ row }">
              <span>{{row.sex == 1 ? '男': row.sex == 2 ? '女':'未知'}}</span>
            </template>
          </ElTableColumn>
          <ElTableColumn label="所属门店" prop="shop_name" />
          <ElTableColumn label="来源" prop="source_name" />
          <ElTableColumn label="客户预存" prop="yucun" />
          <ElTableColumn label="保有/流失" prop="baoliu"/>
          <ElTableColumn label="年度消费金额" prop="year_amount" />
          <ElTableColumn label="门诊消费金额" prop="mz_amount" />
          <ElTableColumn label="门店消费金额" prop="md_amount" />
          <ElTableColumn label="门店贡献价值" prop="gongxian" />
          <ElTableColumn label="最新消费时间" prop="last_xiaofei" />
          <ElTableColumn label="最新核销时间" prop="last_hexiao" />
          <ElTableColumn label="成交时间" prop="cj_time" />
          <ElTableColumn label="护理师" prop="nurse_name" />
          <ElTableColumn label="最新回访时间" prop="last_kftime" />
          <ElTableColumn label="流失预警日期" prop="warning_time" />

        </el-table>
        <pagination
          class="page tc mt10"
          :total="page.total"
          :page.sync="page.page"
          :limit.sync="page.limit"
          @pagination="getList"
        />
      </el-col>
    </el-row>
  </div>
</template>

<script>
import { getStore } from "@/utils/store";
import pagination from "@/components/Pagination";
import QRCode from 'qrcode';
import { getCustomerDetails } from "@/api/statistics";
import { getTelephone } from "@/api/pay";

class Page {
  total = 0;
  page = 1;
  limit = 10;
}
export default {
  name: "keepCustomerDetail",
  components: {
    pagination
  },
  data() {
    return {
      data: {},
      exportS: false,
      admin_level: getStore('admin_level'),
      level: this.$route.query.level,
      type: this.$route.query.type,
      page: new Page(), // 分页
      list: [], // 数据展示
      phoneNumber: '',
      popperOptions: {
        eventsEnabled: true
      }
    }
  },
  mounted() {
    this.getData();
    this.getList();
  },
  methods: {
    getData() {
      let query = this.$route.query;
      switch (query.type) {
        case 'all':
          this.data = {
            'baoliu' : 'all',
            'customer_ids':query.customerIds,
            'retained_ids' : query.retained_ids,
            'lost_ids' : query.lost_ids,
            'page' : this.page.page,
            'limit' : this.page.limit
          }
          break; 
        case 'baoyou':
          this.data = {
            'baoliu' : 'baoyou',
            'retained_ids' : query.retained_ids,
            'page' : this.page.page,
            'limit' : this.page.limit
          }
          break;
        case 'liushi':
          this.data = {
            'baoliu' :'liushi',
            'lost_ids' : query.lost_ids,
            'page' : this.page.page,
            'limit' : this.page.limit
          }
          break;
        case 'yujing':
          this.data = {
            'baoliu' : 'liushi',
            'lost_warning_ids': query.lost_warning_ids,
            'page' : this.page.page,
            'limit' : this.page.limit
          }
          break;
        case 'huiliu':
          this.data = {
            'baoliu' : 'baoyou',
            'reflux_ids': query.reflux_ids,
            'page' : this.page.page,
            'limit' : this.page.limit
          }
          break;
      }
    },

    goCustomerDetail(row){
      let query = {
        id: row.id,
        activeName: "first",
        customer_name: row.customer_name,
        pageName: "客户列表",
      }
      let route = this.$router.resolve({ name: "客户详情", query:query });
      window.open(route.href, '_blank');
    },
    // 获取列表
    getList() {
      this.getData();
       getCustomerDetails(this.data).then(res => {
        this.list = res.data.list.data;
        this.list.map((item,index) => {
          this.$set(this.list,index,{
            ...item,
            phoneImageUrl : '',
            popVisible : false,
          })
        })
        this.page.total = res.data.list.total;
      });
    },
    // 导出表格
    async exportExcel() {
      this.getData();
      this.data.is_export = 1;
    // this.exportS = true;
    const { data } =  await getCustomerDetails(this.data);
    if(data){
      window.open(data.url);
      this.exportS = false
    }

    },
    // 获取手机号二维码
    openPhoneImage(index,id){
      getTelephone({'id':id}).then(res => {
        this.phoneNumber = res.data;
        // 生成 tel: 协议的链接
        const telLink = `tel:${this.phoneNumber}`;
        // 使用 async/await 处理 Promise
        (async () => {
          try {
            const dataUrl = await QRCode.toDataURL(telLink);
            this.list[index].phoneImageUrl = dataUrl;
            this.list[index].popVisible = true;
          } catch (error) {
            console.error('生成二维码时出错:', error);
          }
        })();
        });
    },
    closePhoneImage(index){
      this.$nextTick(() =>{
        this.list[index].popVisible = false;
      })
    }
  }
};
</script>

<style lang="scss" scoped>
.accountCon-title{
  margin-bottom: 20px;
}
.hand {
  cursor: pointer;
  &:hover {
    color: #33bea7;
  }
}
.img {
  width: 225px;
  height: 225px;
}
</style>
